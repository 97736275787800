<template>
  <div class="app-container">
    <CrudTable
      ref="table"
      entity="dossiersBoard"
      :allow-actions="allowactions"
      :allow-create="allowcreate"
      :allow-delete="allowdelete"
      :disable-filtering="disablefiltering"
      :columns="columns"
      :on-row-dbl-click="onRowDblClick"
    />
  </div>
</template>

<script>
import crudIndexMixin from '@/mixins/crud-index';
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  mixins: [crudIndexMixin],
  props: {
    allowactions: { type: Boolean, value: false },
    allowcreate: { type: Boolean, value: false },
    allowdelete: { type: Boolean, value: false },
    disablefiltering: { type: Boolean, value: true }
  },
  data() {
    return {
      columns: [
        { field: 'id', label: 'dossier.dossierID', width: 85 },
        // { field: 'loanID', label: 'loan.actNumber', width: 85 },
        { field: 'loanType', label: 'loan.loanType' },
        { field: 'requestDate', type: 'date', label: 'loan.requestDate', width: 100 },
        { field: 'amount', label: 'loan.amount', width: 100 },
        { field: 'status', label: 'loan.requestStatus', width: 200 },
        { field: 'initials', label: 'common.initialsShort', width: 60 },
        { field: 'middleName', label: 'common.middleNameShort', width: 60 },
        { field: 'lastName', label: 'common.lastName' },
        { field: 'gender', label: 'common.gender' },
        { field: 'dateOfBirth', type: 'date', width: 100 },
        { field: 'contractType' },
        { field: 'endDateContract', width: 100 },
        { field: 'salaryScale' }
      ]
    };
  },
  methods: {
    onRowDblClick(row) {
      this.$router.push({
        name: 'ViewLoan',
        params: { id: row.loanID, dossierID: this.$route.params.id, returnToPage: 'indexBoard' }
      });
      this.$emit('view');
    }
  }
};
</script>

<style scoped></style>
